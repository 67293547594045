.vision-content {
    background-color: $light-purple;

    padding-top: 100px;
    padding-bottom: 100px;
}

.vision-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}
.vision-img {
    width: 50%;
    margin-right: 5%;
}
.vision-content h6{
    font-size: 24px;
    font-weight: 600;
    color: $main-purple;
}

.vision-content h2{
    font-size: 28px;
    line-height: 150%;
    font-weight: 600;

}

@media screen and (max-width: 900px) {
    .vision-wrapper {
        flex-direction: column;
    }
    .vision-img {
        width: 100%;
        margin-right: 0%;
        margin-bottom: 50px;
    }
    .vision-img img {
        width: 70%;
        margin-left: auto;
        display: block;
        margin-right: auto;
    }
    .vision-content h2{
        width: 100%;
        text-align: center;
        font-size: 30px;
    }
    .vision-content h6{
        text-align: center;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 600px) {
    .vision-content h2{
        font-size: 24px;
    }

    .vision-content h6{
        margin-top: 50px;
    }
}