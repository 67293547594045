@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,800&display=swap');
@import "./styles/base.scss";
@import "./styles/colors.scss";
@import "./styles/announcementBar.scss";
@import "./styles/landing.scss";
@import "./styles/vision.scss";
@import "./styles/technologies.scss";
@import "./styles/projects.scss";
@import "./styles/client.scss";
@import "./styles/team.scss";
@import "./styles/info.scss";

@import "./styles/footer.scss";

@import "./styles/applicationPage.scss";
.main-btn {
  background-color: $main-purple;
  color: white;
  text-decoration: none;
  font-weight: 500;
  padding: 16px 32px;
  border-radius: 999px;
  transition: .5s all;
  border: none;
}
.outline-btn {

  color: $main-purple;
  text-decoration: none;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 999px;
  transition: .5s all;
  border: 2px solid $main-purple;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
}
.outline-btn:hover {
  cursor: pointer;
}
.main-btn:hover {
  background-color: $main-purple-hover;
}

.container{
  width: 90%;
  max-width: 1500px;
  margin: 0 auto;
}