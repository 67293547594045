.announcement-bar {
  background-color: $main-purple; // Main purple
  color: #ffffff; // White text
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.announcement-text {
  margin: 0;
  font-size: 16px;

  a {
    color: $main-green; // Green link
    text-decoration: underline;

    &:hover {
      color: $main-purple-hover; // Hover color
    }
  }
}

@media (max-width: 768px) {
  .announcement-text {
    font-size: 14px;
  }
}
