.info {
    background: $main-purple;
    padding-top: 150px;
    padding-bottom: 150px;
}
.info h6 {
    color: $main-green;
    font-size: 20px;
    font-weight: 600;
}
.info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
}
.info-row-even {
    flex-direction: row-reverse;
}
.info-row img {
    width: 40%;
}
.info-row-text {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.info-row-text h3{
    color: $text-purple;
    font-size: 40px;
    font-weight: 600;
    width: 70%;
}
.info-row-text-even {
    text-align: right;
    align-items: end;

}
.info-row-text p {
    color: $background-purple;
    line-height: 160%;
    width: 70%;
    margin-top: 20px;
    font-size: 18px;
}

@media screen and (max-width: 950px) {
    .info h6{
        text-align: center;
        margin-bottom: -50px;
    }
    .info-row {
        flex-direction: column;
        align-items: center;
    }
    .info-row-even {
        flex-direction: column;
    }
    .info-row img {
        width: 80%;
        max-width: 400px;
        margin-top: 50px;
    }
    .info-row-text {
        width: 100%;
        text-align: center;
        align-items: center;
    }
    .info-row-text-even {
        text-align: center;
        align-items: center;
    }
    .info-row-text h3{
        width: 80%;
    }
    .info-row-text p {
        width: 80%;
    }
}