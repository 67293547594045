.container {
  width: 95%;
  margin: 0 auto;
  max-width: 1500px;
}

.landing-bg-bottom {
  width: 100%;
  z-index: 1;
  margin-top: -100px;
}

.landing-graphics {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  margin-top: 150px;
}

.landing-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.logo-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.logo-top img {
  width: 100%;
  max-width: 36px;
}

.logo-top h3 {
  color: #370f7e;
  font-weight: 600;
  font-size: 20px;
}

.landing-title {
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  color: #370f7e;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 150px;
}

.landing-subtitle {
  font-size: 16px;
  line-height: 166%;
  text-align: center;
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  font-weight: 200;
  margin-top: 10px;
}

.landing-buttons {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.landing-button-filled {
  background-color: #370f7e;
  color: white;
  padding: 15px 30px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0px 10px;
}

.landing-button-outlined {
  background: white;
  color: #370f7e;
  padding: 15px 30px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  border: 1px solid #370f7e;
  cursor: pointer;
  transition: 0.3s;
  margin: 0px 10px;
}

.landing-bg-bottom {
  margin-bottom: -50px;
}

@media screen and (max-width: 1300px) {
  .landing-graphics img:nth-child(2) {
    display: none;
  }

  .landing-graphics {
    flex-direction: column;
  }

  .landing-graphics img {
    width: 100%;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 800px) {
  .landing-title {
    font-size: 30px;
    margin-top: 50px;
  }

}
