.client {
    background-color: $light-purple;
    padding-top: 100px;
    padding-bottom: 100px;
}

.client h6{
    color: $main-purple;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px;
}
.client h3{
    font-size: 32px;
    font-weight: 600;
    line-height: 120%;
    width: 80%;
    margin-bottom: -10px;
}

.client-content {

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -20px;
}

.client-content-left {
    width: 70%;
}
.client-content img {
    width: 25%;
}

.client-content-left p {
    margin-bottom: 25px;
    line-height: 160%;
    font-weight: 300;
    font-size: 18px;
}

.client-btn {
    display: block;
    width: fit-content;

}
@media screen and (max-width: 1000px) {
    .client-content {
        flex-direction: column;
        align-items: center;
    }
    .client-content-left {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .client-content img {
        width: 70%;
        max-width: 300px;
        margin-top: 50px;
    }
    .client-btn {
        margin-top: 50px;
    }
    .client h6{
        text-align: center;
    }
    .client h3{
        text-align: center;
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
    }

}