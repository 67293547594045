.team {
  background-color: $background-purple;
  padding-top: 100px;
  padding-bottom: 100px;
}
.team h4 {
  color: $main-purple;
  font-size: 24px;

  font-weight: 600;
}
.team-subtitle {
  font-size: 20px;
  line-height: 166%;

  font-weight: 200;
  margin-bottom: 50px;
}

.team-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 25px;
}

.team-member {
  background: white;
  padding: 40px;
}
.italic {
  font-style: italic;
}
.team-member img {
  width: 50%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1/1;
  border: 2px solid $main-purple;
  border-radius: 990px;
  margin-bottom: 10px;
}

.team-member h5 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
}
.team-member h6 {
  margin-top: -3px;
  font-weight: 200;
  font-size: 16px;
}
.team-member p {
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  margin-top: 5px;
}

.team-member-links a {
  font-size: 24px;
  text-decoration: none;
  margin-top: 10px;
  color: $main-purple;
  margin-right: 5px;
}

@media screen and (max-width: 1150px) {
  .team-content {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 850px) {
  .team-content {
    grid-template-columns: 1fr 1fr;
  }
  .team h4 {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .team-content {
    grid-template-columns: 1fr;
  }
  .team h4 {
    text-align: center;
  }
}
