.projects .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects h3 {
  color: $main-purple;
  font-weight: 600;
  font-size: 36px;
}
.projects h6 {
  font-size: 20px;
  font-weight: 300;
}

.projects-placeholder-container {
  padding-top: 250px;
  padding-bottom: 250px;
  background: $background-purple;
  width: 100%;
  margin: 20px;
  border-radius: 5px;
  text-align: center;
  display: none;
}
.projects-placeholder-container p {
  font-size: 20px;
  margin-bottom: 20px;
}
.projects-placeholder-container h4 {
  font-weight: 600;
  font-size: 32px;
}
.projects-placeholder-container h6 {
  color: $main-purple;
  font-size: 24px;
}
.projects-container {
  //for now
  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-gap: 20px;
  margin-top: 50px;
  margin-bottom: 100px;
  max-width: 1200px;
}

.project {
  background: white;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.project-name {
  display: flex;
  align-items: center;
}

.project-name a {
  margin-left: 15px;
  padding: 4px 8px;
  font-size: 12px;
  border: 1px solid $main-purple;
  border-radius: 2px;
  color: $main-purple;
  text-decoration: none;
}
.project-image {
  width: 100%;
  margin-bottom: 20px;
}

.project h4 {
  font-size: 24px;
  font-weight: 600;
  color: $main-purple;
}
.project hr {
  width: 15%;
  margin-top: 5px;
  margin-bottom: 15px;
  background-color: $main-green;
  height: 3px;
  border: none;
}

.project-items {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.project-items p {
  font-size: 14px;
  border-radius: 3px;
  padding: 5px 10px;
  color: $main-purple;
  border: 1px solid $main-purple;
  margin-right: 7px;
}

.projects-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 20px;
}

.project-button-active {
  background-color: #370f7e;
  color: white;
  padding: 15px 30px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  margin: 0 5px;
}
.project-button {
  background: white;
  color: #370f7e;
  padding: 15px 30px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  border: 1px solid #370f7e;
  margin: 0 5px;
}
@media screen and (max-width: 1000px) {
  .projects-container {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 750px) {
  .projects h6 {
    text-align: center;
  }
  .projects-placeholder-container {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .projects-placeholder-container h4 {
    font-weight: 600;
    font-size: 20px;
  }
  .projects-placeholder-container p {
    font-size: 16px;
  }
}
