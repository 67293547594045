.application-page {
  min-height: 100vh;
  background: linear-gradient(180deg, #160337, #1E0B3F), $dark-purple;
  padding-bottom: 100px;
}

.application-page-back {
  text-decoration: none;
  position: fixed;
  top: 30px;
  left: 20px;
  color: $text-purple;
  font-size: 14px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border: 1px solid $text-purple;
  border-radius: 100px;
  z-index: 10;
}

.application-page-top {
  padding-top: 10vh;
}

.application-page-modal button {
  background: $main-purple;
  border: none;
  border-radius: 100px;
  padding: 10px 30px;
  color: $background-purple;
  font-size: 14px;
  font-weight: 600;
  margin: 0 auto;
  display: block;
  margin-top: 20px;

  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.application-page-container h4 {
  color: $main-green;
  font-size: 20px;
  font-weight: 500;
}

.application-page-container h1 {
  color: $text-purple;
  font-weight: 500;
  font-size: 48px;
  margin-top: -10px;
}

.application-bottom-image {
  position: fixed;
  bottom: 20px;
  opacity: 0.5;
}

.application-page-left p {
  color: white;
}

.netid-input {
  width: calc(100% - 30px);
  height: 150px;
  border-radius: 3px;
  background: transparent;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 128px;
  padding-left: 0px;
  font-family: "Poppins";
}

.netid-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
  font-size: 36px;
  font-family: "Poppins";
}

.application-top-image {
  width: 100%;
  position: fixed;
  top: 0px;
  opacity: .2;
  left: 0px;
}

.application-page-container {
  display: flex;
  flex-direction: column;

  align-items: start;
  padding-top: 10vh;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;

}

.application-page-modal {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba($color: #0F0321, $alpha: 0.8);
  backdrop-filter: blur(10px);
  z-index: 5;
}

.cab-fair-container {
  width: 80% !important;
  padding-top: 150px;
}

.cab-fair-right {
  margin-top: 100px !important;
  background: transparent !important;
  border: none !important;
  padding: 0px !important;
  border-bottom: 4px solid $main-purple !important;
}

.cab-fair-container h6 {
  color: white;
  font-size: 20px;
  color: #eee;
  font-weight: 300;
  text-align: left;

  width: calc(100% - 50px);
  margin-top: 100px;
  margin-right: auto;
}

.cab-fair-container h6 span {
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 3px;
  color: $main-green;
  border: 2px solid $main-green;


}

.application-page-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  border-radius: 5px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 6;
}

.application-page-modal-content h3 {
  color: $background-purple;
  font-size: 32px;
  font-weight: 500;
  margin-top: 20px;
}

.application-page-modal-check {
  color: $main-green;
  font-size: 60px;
  margin-top: 20px;
}

.application-page-modal p {
  color: #ccc;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.application-page-right {

  padding: 40px;
  width: 90%;
  margin-top: 20px;
  background: rgba($color: #0F0321, $alpha: 0.5);
  backdrop-filter: blur(10px);
  position: relative;
  border: 2px solid $main-purple;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.application-page-input-container {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.application-page-input-container h6 {
  color: #888;
  font-weight: lighter;
  font-size: 18px;
  margin-bottom: 5px;
}

.application-page-input {
  position: relative;
  display: flex;
  width: 100%;
}

.application-page-select {
  width: 100%;
  color: white;

}

.application-page-container-double {
  display: flex;
  flex-direction: row;
  justify-content: space-between;


}

.application-page-container-double>.application-page-input-container {
  width: calc(50% - 10px);
}


.application-page-input input {
  font-family: "Poppins";
  height: 50px;
  border-radius: 3px;
  background-color: #222;
  border: none;
  color: #ccc;
  font-size: 18px;
  padding-left: 50px;
  width: calc(100% - 50px);

}

.application-page-input-caret {
  color: $main-green;
  position: absolute;
  left: 20px;
  top: 16px;
}


input[type="file"] {
  display: none;
}

label {
  display: block;
  width: calc(100% - 50px);
  padding-left: 50px;
  height: 50px;
  border-radius: 3px;
  background-color: #222;
  border: none;
  color: #888;
  font-size: 16px;

  line-height: 50px;
  cursor: pointer;
}


.application-page-input-container p {
  color: #ccc;
  font-weight: lighter;
  font-size: 14px;
  margin-bottom: 15px;
}

.resume-indicator {
  color: #aaa;
  margin-top: 15px;


}

.textarea {
  height: 300px;
  min-width: calc(100% - 100px);
  background-color: #222;
  font-family: "Poppins";
  border-radius: 3px;
  border: none;
  color: #ccc;
  font-size: 16px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 12px;

}

input[type="submit"] {
  width: 100%;
  height: 70px;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid $text-purple;
  color: $text-purple;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
  transition: 0.2s;
}

@media screen and (max-width: 800px) {
  .application-page-back {
    position: absolute;
  }

  .application-page-container h1 {
    font-size: 32px;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .application-page-container-double {
    flex-direction: column;
  }

  .application-page-container-double>.application-page-input-container {
    width: 100%;
  }

  .application-page-right {

    padding: 20px;
  }
}
