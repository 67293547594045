.technologies-content {
  background-color: #370f7e;
  padding-top: 200px;
  padding-bottom: 200px;
}

.technologies-content .container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.technologies-content-left {
  width: 35%;
}

.technologies-content-left h6 {
  color: $main-green;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600;
}
.technologies-content-left img {
  width: 100%;
}
.technologies-content-right {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-items: end;
}
.technologies-bottom {
  width: 60%;
}
.technologies-content-right h3 {
  font-size: 32px;
  color: $light-purple;
  font-weight: 500;
  line-height: 150%;
  width: 80%;
  margin-bottom: 50px;
  text-align: right;
}

.technologies-content-right-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
}

.technologies-content-right-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}
.technologies-content-right-list-item h5 {
  color: $text-purple;
  font-weight: 600;
  font-size: 36px;
  padding-right: 10px;
  padding-left: 30px;
}

//1200 px media query
@media screen and (max-width: 1200px) {
  .technologies-content-right-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 1000px) {
  .technologies-content .container {
    flex-direction: column;
  }
  .technologies-content-left {
    width: 100%;
    text-align: center;
  }
  .technologies-content-left img {
    width: 60%;
    margin-top: 40px;
  }

  .technologies-content-right {
    width: 100%;
    align-items: center;
  }
  .technologies-content-right h3 {
    width: 90%;
    text-align: center;
    margin-top: 30px;
    font-size: 24px;
  }

  .technologies-content-right-list-item {
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .technologies-content-right-list-item h5 {
    font-size: 24px;
  }
}
@media screen and (max-width: 400px) {
  .technologies-content-right-list {
    grid-template-columns: 1fr;
  }
}
