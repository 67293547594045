.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.footer img{
    width: 30px;

    margin-top: 20px;
}
.footer-top{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.footer-top h3{
    margin-left: 10px;
    line-height: 50px;
    margin-top: 15px;
}
.footer h3{
    color: $main-purple;
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    width: 100%;
}

.tos {
    text-decoration: underline;
    margin-top: -10px;
    margin-bottom: 10px;
}
.tos:hover {
    color: $main-purple;
    cursor: pointer;
}

.markdown {
    margin-bottom: 40px;
    font-weight: 600;
    padding: 20px;
}
.modal-btn {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    display: block;
}

.markdown p{
    font-weight: 300;
}
.markdown h1, h2 {
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
}